import { Box, Stack, Button, styled, colors } from '@mui/material';
import { blue } from '@mui/material/colors';
import React from 'react';
import { Link } from 'react-router-dom';
import logowhats from '../../img/whatsapp.svg';
const ButLink = styled(Button)({
    color: 'white',
    fontWeight: 700,
    fontSize: '14px',

})

export const Footer = () => {
    return (
        <Box className='footer' >
            <Stack direction='row'
                justifyContent= {{xs:'space-between',sm:'space-between',md:'space-between'}}
                alignItems="center"
                className='container'
                flexWrap="wrap"
            >

                <Box>
                    <Link to='/about' style={{ textDecoration: 'none' }}>
                        <ButLink>О компании</ButLink>
                    </Link>
                    {/* <ButLink>Клиентам</ButLink> */}
                    {/* <ButLink>Поставщикам</ButLink> */}
                </Box>
                <Box sx={{padding:1, display:{xs:'block',md:'none'}}}>
                <a href="tel:89994633000" className='none-dicoretion'>
                    <Link to='https://wa.me/79994633000' className="none-dicoretion" target="_blank">
                        <img src={logowhats}></img>
                    </Link>
                    </a>
                </Box>
                <Box>
                    {/* <ButLink>Оплата</ButLink> */}
                    {/* <ButLink>Доставка</ButLink> */}

                    <Link to='/address' style={{ textDecoration: 'none' }}>
                        <ButLink>Адреса</ButLink>
                    </Link>

                </Box>

            </Stack>
        </Box>)
}

