import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";


function EmptyData() {
    return (
        <Box sx={{ width: '100%' }} padding={2} textAlign={{ xs: 'center', sm: 'center', lg: 'start' }}>
            <Typography fontSize={20}>
                Товар не найден на складе.
            </Typography>
            <Box>
                <Typography display="inline" fontSize={20}>
                    Для уточнения информации о поступлении обратитесь к менеджеру по номеру
                </Typography>
                <Typography display="inline" fontSize={20} fontWeight={700}> 8(999)463-30-00 </Typography>
                <a href="tel:89994633000">
                    <Link to='https://wa.me/79994633000' className="none-dicoretion-color none-dicoretion" target="_blank">
                        <Typography display="inline" fontSize={20} fontWeight={700}>
                            (доступен по whatsapp)
                        </Typography>
                    </Link>
                </a>

            </Box>
        </Box>
    )
}

export default EmptyData;