import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

export default function PictureView(props) {
    return (
        <Dialog 
            fullWidth={true}
            maxWidth='md'
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {props.number}
            </DialogTitle>
            <img src={props.url} />
            <IconButton
                aria-label="close"
                onClick={props.handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </Dialog>
    )
}