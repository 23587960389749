import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GetSearch, GetInfoNumber } from '../../api/axiosApi';

export const fetchGetFilter = createAsyncThunk(
  'product/fetchGetFilter',
  async (obj) => {
    //obj.searchofarticle=thunkAPI.getState().product.isChecked
    const response = await GetSearch(obj);    
    return response;
  }
)

export const fetchGetInfoNumber=createAsyncThunk(
  'product/fetchGetInfoNumber',
  async (obj) => {
    const response = await GetInfoNumber(obj);    
    return response;
  }
)

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    catalogList: {
      catalog: 'Общий каталог',
      dvigatel: 1,
      systemStupid: 2,
      filters: 3,
      rule: 4,
      systemcould: 5,
      transmission: 6,
      smookesystem: 7
    },
    strSearch: '',
    articul: "",
    vinNumber: '',
    marka: '',
    category: '',
    producer: '',
    dataSearch: {},
    isLoadData: false,
    isLoadPrice:false,
    isOpenModal:false,
    isChecked:false,
    currentInfoForNumber:{}
  },
  reducers: {
    changeArticul: (state, action) => {
      state.articul = action.payload;
    },
    changeVinNumber: (state, action) => {
      state.vinNumber = action.payload;
    },
    changeCategory: (state, action) => {
      state.category = action.payload;
    },
    changeStrSearch: (state, action) => {
      state.strSearch = action.payload;
    },
    zeroDataSearch:(state)=>{
      state.dataSearch={};      
    },
    eventChecked:(state,action)=>{
      state.isChecked=action.payload;      
    },
    changeDefaultValue:(state,action)=>{
      state.articul=action.payload[0].value;
      state.vinNumber=action.payload[2].value;
      state.category=action.payload[6].value;
      state.marka=action.payload[4].value;      
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetFilter.pending, (state) => {
        state.isLoadData = false;
      })
      .addCase(fetchGetFilter.fulfilled, (state, action) => {
        state.isLoadData = true;
        state.dataSearch = action.payload;        
      })
      .addCase(fetchGetInfoNumber.pending, (state)=>{
        state.isLoadPrice=false;
      })
      .addCase(fetchGetInfoNumber.fulfilled,(state,action)=>{
        state.isLoadPrice = true;
        state.currentInfoForNumber = action.payload;        
      })
  }
})



export const { zeroDataSearch,eventChecked,changeDefaultValue,fetchSearch, changeArticul, changeVinNumber, changeMarka, changeCategory, changeProducer, changeStrSearch } = productSlice.actions

export default productSlice.reducer
