import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    activeMenu:false
  },
  reducers: {    
    changeActiveMenu:(state)=>{
        state.activeMenu=!state.activeMenu;
    }
  },
})



export const { changeActiveMenu } = layoutSlice.actions;

export default layoutSlice.reducer
