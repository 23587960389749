import { Paper, Stack, Box, Typography } from "@mui/material"
import { TypographyHeaderPage } from '../style/Styled'
import { Link } from "react-router-dom";

const AboutCompany = () => {

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Stack direction="column"
                justifyContent="flex-start"
                alignItems="center"
                padding={2}
                spacing={1}>
                <TypographyHeaderPage>
                    МАГАЗИН АВТОЗАПЧАСТЕЙ
                </TypographyHeaderPage>
                <Stack
                    sx={{ width: '100%' }}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="start"
                    spacing={1}>
                    <Typography fontWeight={700}>НадоДетали</Typography>
                    <Typography variant="body1">специализируется на подборе и доставке запчастей и расходных материалов для автомобилей.</Typography>
                </Stack>
                <Typography variant="body1" sx={{ width: '100%' }}>
                    Наша компания сотрудничает с поставщиками самых известных и проверенных брендов (Kayba, NOK, G-Brake, Just Drive и др.)
                </Typography>
                <Typography variant="body1" sx={{ width: '100%' }}>
                    Мы ежедневно обновляем электронный каталог нашего склада, чтобы Вы всегда могли получить актуальную информацию и найти нужные Вам автозапчасти.
                </Typography>
                <Typography variant="body1" sx={{ width: '100%' }}>
                    Наши менеджеры подберут и проконсультируют Вас по всей необходимой продукции, подберем запчасти согласно каталогов производителей. По необходимости
                    <span style={{ fontWeight: 700 }}> организуем доставку </span>
                    в согласованное время и место.
                </Typography>

                <Typography variant="body1" sx={{ width: '100%' }}>
                    Для максимально комфортного общения Вы можете написать нам в <span>
                        <Link to='https://wa.me/79994633000' className="none-dicoretion" target="_blank" style={{ fontWeight: 700, color: 'black' }} href="/">
                            чат компании (Whatsapp)
                        </Link>
                    </span>
                </Typography>
                <TypographyHeaderPage>
                    Автосервис
                </TypographyHeaderPage>
                <Typography variant='body1' width='100%'>
                    Установить приобретенные автодетали Вы можете воспользовавшись услугами нашего специализированного автосервиса! Гарантия работ и профессиональной установки!
                </Typography>
                <Stack sx={{ width: '80%' }}
                    direction={{ xs: "column", md: 'row' }}
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}>
                    
                        <img
                            style={{ height: "100%", width: "100%" }}
                            src="https://www.nadodetali.ru/images/aboutImage1.png"
                            alt="aboutImage1"
                            loading="lazy"
                        />
                    

                    <img
                        style={{ height: "100%", width: "100%" }}
                        src="https://www.nadodetali.ru/images/aboutImage2.png"
                        alt="aboutImage2"
                        loading="lazy"
                    />
                    <img
                        style={{ height: "100%", width: "100%" }}
                        src="https://www.nadodetali.ru/images/aboutImage3.png"
                        alt="aboutImage3"
                        loading="lazy"
                    />
                </Stack>
                
            </Stack>
        </Box>
    )

}

export default AboutCompany