import { configureStore } from '@reduxjs/toolkit'
import productReduce from '../pages/slices/productSlice';
import  basketSlice  from '../pages/slices/basket/slice';
import layoutSlice from '../pages/slices/layout/state';
import { generalPageSlice } from '../pages/slices/generalPage/state';

export default configureStore({
  reducer: {
    product: productReduce,
    basket:basketSlice,
    layout:layoutSlice,
    general:generalPageSlice
  }
})