import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { EventAddOrder } from '../../../api/axiosApi';

export const fetchEventAddOrder = createAsyncThunk(
  'product/fetchEventAddOrder',
  async (_, thunkAPI) => {
    let arraynumber = []
    thunkAPI.getState().basket.listOrder.forEach(element => {
      arraynumber.push(
        {
          "article": element.obj.number, "count": element.count, "item": element.obj.id, "brand": element.obj.brand,
          "price": element.obj.prices
        }
      );
    })
    const response = await EventAddOrder({
      "name": thunkAPI.getState().basket.name,
      "phonenumber": thunkAPI.getState().basket.phonenumber,
      "addresstransfer": thunkAPI.getState().basket.addresstransfer,
      "isTransfer": thunkAPI.getState().basket.isTransfer,
      "status": 0,
      "price": thunkAPI.getState().basket.generalPrice,
      "listProduct": arraynumber,
      "body": thunkAPI.getState().basket.bodyClient
    });
    return response;
  }
)

export const basketSlice = createSlice({
  name: 'basket',
  initialState: {
    listOrder: [],
    listValues: [],
    generalPrice: 0,
    isRepeat: false,
    openAlert: false,
    isAddOrder: false,
    resultAddOrder: {},
    name: '',
    phonenumber: '',
    addresstransfer: '',
    bodyClient: '',
    isTransfer: false
  },
  reducers: {
    changeName: (state, action) => {
      state.name = action.payload;
    },
    changePhonenumber: (state, action) => {
      state.phonenumber = action.payload;
    },
    changeAddresstransfer: (state, action) => {
      state.addresstransfer = action.payload;
    },
    changeisTransfer: (state, action) => {
      state.isTransfer = action.payload;
    },
    changeBodyClient: (state, action) => {
      state.bodyClient = action.payload;
    },
    addProduct: (state, action) => {
      state.listOrder.push({ obj: action.payload, count: 1, priceGenerel: action.payload.prices })
      state.generalPrice = state.generalPrice + action.payload.prices;
      state.openAlert = true;
      state.isRepeat = true;
    },
    handlerAlert: (state, action) => {
      state.openAlert = action.payload;
    },
    checkBasket: (state, action) => {
      let count = 0;
      state.listOrder.forEach(element => {
        if (element.obj.number === action.payload) {
          count++;
        }
      });
      if (count === 0) {
        state.isRepeat = false;
      } else {
        state.isRepeat = true;
      }
    },
    addProductFromList: (state, action) => {
      let count = 0;
      state.listOrder.forEach(element => {
        if (element.obj.number === action.payload.number) {
          count++;
        }
      });
      if (count === 0) {
        state.listOrder.push({ obj: action.payload, count: 1, priceGenerel: action.payload.prices })
      } else {
        state.listOrder.forEach(element => {
          if (element.obj.number === action.payload.number && element.count<element.obj.count) {
            element.count++;
          }
        });
      }
    },
    removeProduct: (state, action) => {
      state.generalPrice = state.generalPrice - action.payload.price;
      state.listOrder.splice(state.listOrder.findIndex((opt) => opt.id === action.payload.id), 1);
    },
    changePrice: (state, action) => {
      let new_price = 0;
      state.listOrder.map(
        (row) => {
          if (row.obj.number === action.payload.row.obj.number)
            row.count = action.payload.value;
          row.priceGenerel = row.count * row.obj.prices;
        });
      state.listOrder.map(
        (row) => {
          new_price = new_price + row.priceGenerel;
        })
      state.generalPrice = new_price;
    },
    changePriceLoadPage:(state)=>{
      let new_price = 0;
        state.listOrder.map(
          (row) => {        
            row.priceGenerel = row.count * row.obj.prices;
          });
        state.listOrder.map(
          (row) => {
            new_price = new_price + row.priceGenerel;
          })
        state.generalPrice = new_price;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventAddOrder.pending, (state) => {
        state.isAddOrder = true;
      })
      .addCase(fetchEventAddOrder.fulfilled, (state, action) => {
        state.isAddOrder = false;
        state.listOrder = [];
        state.isTransfer=false;
        state.resultAddOrder = action.payload;
      })
  }
})



export const { addProduct, removeProduct, changePrice, handlerAlert, checkBasket,
  changeName, changePhonenumber, changeAddresstransfer, changeisTransfer, changeBodyClient, addProductFromList,changePriceLoadPage } = basketSlice.actions

export default basketSlice.reducer
