import { useEffect, useState } from "react"
import { Box, Stack, Typography, styled } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import address2 from '../img/address2.png'

export const Address = () => {
    const [isLoad, setIsLoad] = useState(false);
    //const data = useSelector(state => state.general.data);

    const TypographyItem = styled(Typography)({
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 18,
    });

    return (
        <Grid2 container
            ml={{ xs: 0, md: 4.5 }}
        >
            <Grid2 xs={12} >
                <Typography textAlign='center' fontWeight={700} padding={2} sx={{ fontSize: '20px', lineHeight: '24.2px' }}>АДРЕСА НАШИХ МАГАЗИНОВ</Typography>
            </Grid2>
            <Grid2 padding={2} xs={12} md={6}>
                <Stack direction={{ xs: 'column', md: 'column' }}
                    justifyContent="flex-start"
                    alignItems={{ xs: 'center', md: 'start' }}
                    textAlign={{ xs: 'center', md: 'start' }}
                >
                    <TypographyItem>Физ. адрес: 630051, г. Новосибирск, ул. Ползунова, 1 к3</TypographyItem>
                    <TypographyItem>ИП Кононов В.Е. (ИНН: 540134892432)</TypographyItem>
                </Stack>
            </Grid2>
            <Grid2 padding={2} xs={12} md={6}>
                <Stack direction={{ xs: 'column', md: 'row' }}
                    justifyContent="flex-start"
                    alignItems={{ xs: 'center', md: 'start' }}
                    spacing={{ xs: 1, md: 11 }}
                    marginBottom={{ xs: 1, md: 0 }}>
                    <TypographyItem>Режим работы</TypographyItem>
                    <TypographyItem>Пн-Вс: с 9:00 до 18:00</TypographyItem>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }}
                    justifyContent="flex-start"
                    alignItems={{ xs: 'center', md: 'start' }}
                    spacing={{ xs: 1, md: 3 }}>
                    <TypographyItem>Контактный телефон</TypographyItem>
                    <TypographyItem>+79994633000</TypographyItem>
                </Stack>
            </Grid2>
            <Grid2 padding={2} xs={12}>
                <Stack
                    sx={{ width: '100%', height: '100%' }}
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="center"
                    alignItems="center"
                    textAlign='center'
                    spacing={5}>
                    <Box sx={{ width: '100%', height: { xs: '300px', md: "100%" } }}>
                        <iframe 
                        style={{width: '100%', height:"100%"}}
                        src="/map.html"
                                />
                    </Box>
                    <img width='100%' style={{ height: '100%', maxWidth: '100%' }} src="https://www.nadodetali.ru/images/address2.png" alt="Logo2" />
                </Stack>

            </Grid2>
            <Grid2 padding={2} xs={12}>
                <Typography textAlign='center' fontWeight={700} padding={2} sx={{ fontSize: '20px', lineHeight: '24.2px' }}>ДОСТУПЕН САМОВЫВОЗ ЛИБО ДОСТАВКА ЗАКАЗА</Typography>
            </Grid2>
        </Grid2>
    )

}