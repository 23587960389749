import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Dialog,
    LinearProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Stack, ListItemButton
} from '@mui/material';
import './ProductInfo.css'
import { useSelector, useDispatch } from 'react-redux'
import { fetchGetInfoNumber, openModal } from './slices/productSlice'
import { Info } from "../components/product-info/Info";


export const ProductInfo = () => {
    let { number } = useParams();
    const [value,setValue] = useState({});
    const dispatch = useDispatch();

    const {
        currentInfoForNumber,
        isLoadPrice
    } = useSelector(state => state.product);


    useEffect(() => {        
            dispatch(fetchGetInfoNumber({"numberValue":number}));            
    }, [])

    const handlerSearch = (e) => {
        e.preventDefault();
        console.log(e)
    }

    return (
        <>
            {isLoadPrice == false ?
                <LinearProgress color="success" />
                :
                <Info item={currentInfoForNumber.obj} />
            }
        </>
    )
}