import axios from 'axios';

export const GetSearch = async (obj) => {
    const result = await axios.post("api/filters/getsearch", obj)
        .then(result => {
            return result.data;
        });
    return result;
}

export const GetInfoNumber = async (obj) => {
    const result = await axios.post("api/filters/getprice", obj)
        .then(result => {
            return result.data;
        });
    return result;
}

export const EventAddOrder=async(obj)=>{
    const result = await axios.post("api/notification/sendorder", obj)
        .then(result => {
            return result.data;
        });
    return result;
}

export const GetPage=async(obj)=>{
    const result = await axios.post("api/page/getpage", obj)
    .then(result => {
        return result.data;
    });
return result;
}