import React, { } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import Filter from '../../pages/Filter';
import { Stack, styled, Button, TextField, Drawer } from '@mui/material';

import { changeArticul, changeVinNumber, changeMarka } from "../../pages/slices/productSlice";
import { Padding } from "@mui/icons-material";
import './filters.css';
export const Filters = () => {

    const BoxItem = styled(Stack)({
        width: '348px',
        position:'sticky',
        top:0,
        width: '100%',        
    });

    const { articul, vinNumber, marka, category, producer } = useSelector(state => state.product);

    const arrayFilter = [
        { "id": "input_0", name: 'articul', "labeltxt": 'АРТИКУЛ ДЕТАЛИ', 'placeholder': 'Артикул', defaultValue: articul },
        { "id": 'input_2', name: 'marka', "labeltxt": 'марка авто', 'placeholder': ' Марку', defaultValue: marka },
        { "id": "input_4", name: 'producer', "labeltxt": 'производитель', 'placeholder': 'Производитель', defaultValue: producer },
    ];

    const arrayComponent = arrayFilter.map((element, index) =>
        <Filter
            key={index + "input"}
            filterName={element.labeltxt}
            value={element.value}
            placeholder={element.placeholder}
            name={element.name}
            defaultValue={element.defaultValue}
        >
        </Filter>
    );

    return (
        <BoxItem           
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
            pt={4}
            //position='fixed'
            //sx={{width:'23rem'}}
        >
            {
                arrayComponent
            }
            <Stack sx={{ width: '100%', p: 1 }}
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Button className='search-but filter'
                    type="submit"
                    sx={{
                        '&:hover': {
                            backgroundColor: '#56854a !important'
                        }
                    }}>Найти</Button>
            </Stack>


        </BoxItem>
    )
}