import { Box } from "@mui/material";

const MainLayout=({ children })=>{
    return(
        <Box>            
            {children}
        </Box>
 
    )
}

export default MainLayout;