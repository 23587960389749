import React, { Fragment, useEffect, useState } from "react";
import {
    Container, Stack, List, ListItemText, ListItem, Typography, styled, Button, Snackbar,
    Alert
} from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
import { Link } from "react-router-dom";
import { addProduct, handlerAlert, checkBasket } from "../../pages/slices/basket/slice";
import { useSelector, useDispatch } from 'react-redux'
import { createAction } from '@reduxjs/toolkit'
import { SnackbarProvider, useSnackbar } from 'notistack';

export const Info = ({ item }) => {

    const dispatch = useDispatch();
    const { isRepeat, openAlert } = useSelector(state => state.basket);
    const TypographyPrice = styled(Typography)({
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 20,
        color: '#FF5C00',
        textTransform: 'uppercase'
    });

    function addProductC(value) {
        window.ym(95266313, 'reachGoal', 'add-to-cart');
        dispatch(addProduct(value));
    }

    useEffect(() => {
        dispatch(handlerAlert(false));
        dispatch(checkBasket(item.number));
    }, [])

    return (
        <Container sx={{ marginBottom: 3 }}>
            <Grid container spacing={0} padding={0} margin={0} height='100%' sx={{ mt: 5 }}>
                <Grid xs={12} sm={5} sx={{ margin: 0, padding: 0 }}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        {
                            item.photo != null || undefined ?
                                <img src={item.photo} className="img-thumbnail rounded"
                                    style={{ minWidth: 300, height: 300 }}></img>
                                :
                                <HelpOutlineTwoToneIcon sx={{ fontSize: '200px' }} />
                        }
                    </Stack>
                </Grid>
                <Grid xs={12} sm={6} sx={{ margin: 0, padding: 0 }}>
                    <Stack direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}>
                        <List>
                            <ListItem sx={{ p: 0, fontSize: 20 }}>
                                <ListItemText
                                    primaryTypographyProps={{
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: 200,
                                    }}

                                    primary={<span>Артикул: <span>{item.nameFull}</span></span>}
                                />
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemText
                                    primaryTypographyProps={{
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: 200,
                                    }}
                                    primary={<span>Артикул: <span>{item.number}</span></span>}
                                />
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemText
                                    primaryTypographyProps={{
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: 200,
                                    }}
                                    primary={<span>Замены: <span>{item.changes}</span></span>}
                                />
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemText
                                    primaryTypographyProps={{
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: 200,
                                    }}
                                    primary={<span>В наличии: <span>{item.count} шт.</span></span>}
                                />
                            </ListItem>
                        </List>
                        <br />
                        <Stack direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={6}
                        >

                            <TypographyPrice>цена</TypographyPrice>
                            <TypographyPrice>{item.prices}</TypographyPrice>
                            <TypographyPrice>руб/шт</TypographyPrice>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            <Stack direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                sx={{ mt: 10, }}>

                {
                    isRepeat === false ?
                        <Button className='search-but filter'
                            disabled={item.count === 0 ? true : false}
                            sx={{
                                "&.Mui-disabled": {
                                    background: "#8a8a8a!important",
                                },
                                width: '380px !important',
                                '&:hover': {
                                    backgroundColor: '#56854a !important'
                                }
                            }}
                            onClick={_ => addProductC(item)}>Добавить в корзину</Button>
                        : <Link to='/ordering' className="none-dicoretion width-link search-but filter"
                        >
                            <Button className='search-but filter'
                                sx={{
                                    backgroundColor: 'rgb(2, 136, 209) !important',
                                    width: '380px !important',
                                    '&:hover': {
                                        backgroundColor: 'rgb(4, 166, 255) !important'
                                    }
                                }}>
                                Перейти в корзину
                            </Button>
                        </Link>

                }
                <a className="none-dicoretion" href="tel:89994633000">
                    <Link to='https://wa.me/79994633000' className="none-dicoretion" target="_blank">
                        <Button className='search-but filter'
                            type="submit"
                            sx={{
                                width: '380px !important',
                                '&:hover': {
                                    backgroundColor: '#56854a !important'
                                }
                            }}>ВОПРОС МЕНЕДЖЕРУ В WHATSAPP</Button>
                    </Link>
                </a>
            </Stack>

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={openAlert}
                message="2"
                key='Товар добавлен...'>
                <Alert severity="success">Товар добавлен</Alert>
            </Snackbar>


        </Container>
    )
}