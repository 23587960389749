import React, { Component } from 'react';
import { IndexBody } from './main';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <IndexBody />
    );
  }
}
