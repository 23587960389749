import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './NavMenu.css';
import {
  Stack, Box, OutlinedInput, Button, Typography, IconButton, Badge, SvgIcon,
  Checkbox, FormControlLabel
} from '@mui/material';
import logo from '../img/logo-jap-white 1.svg';
import menu from '../img/menu 1.svg';
import logowhats from '../img/whatsapp.svg';
import basket from '../img/basketEmpity.svg';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveMenu } from '../pages/slices/layout/state';
import { eventChecked } from '../pages/slices/productSlice';

const TextNavbar = styled(Typography)({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  color: 'white',
});

export const NavMenu = () => {
  const [strText, setStr] = useState('');
  const [isList, setIsList] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setStr(event.target.value);
  };

  const { isChecked } = useSelector(state => state.product);
  const { listOrder } = useSelector(state => state.basket);

  const enterLink = (e) => {
    if (e.key === 'Enter') {
      navigate(`/products-list/${strText}`);
    }
    setStr(e.target.value);
  }

  useEffect(() => {
    if (location.pathname !== '/')
      setIsList(false);
    else {
      setIsList(true);
    }
  }, [location])

  useEffect(() => {
    if (location.pathname == '/' ||
      location.pathname == '/address' ||
      location.pathname == '/about' ||
      location.pathname == '/ordering') {
      setStr('');
      dispatch(eventChecked(false))
    }
  }, [location])

  return (
    <Stack direction={{ xs: 'column', md: 'column', lg: 'row' }}
      justifyContent={{ xs: 'center', md: 'center', lg: 'space-around' }}
      alignItems="center"
      spacing={{ xs: 0, md: 1, lg: 1 }}
      sx={{ width: '100%', backgroundColor: '#383838' }}
    >

      <Link to='/' className='none-dicoretion' style={{ cursor: 'pointer' }}>
        <Box
          display={{ xs: 'none', md: 'none', lg: 'flex' }}
          minHeight={3}
        >
          <img style={{ width: '100%' }} src={logo}></img>
        </Box>

      </Link>

      <Stack
        direction='row'
        justifyContent={{ xs: 'space-evenly', sm: 'center', md: 'center', lg: 'space-evenly' }}
        spacing={0}
        alignItems="center"
        sx={{
          width: '100%',
          display: { xs: 'flex', md: 'flex', lg: 'none' }
        }}
      >

        {/* <IconButton 
          onClick={()=>dispatch(changeActiveMenu())}                   
          sx={{
            marginTop:3
          }}
        >
          <img style={{ width: '100%' }} src={menu}></img>
        </IconButton> */}

        <Box>
          <Link to='/' className='none-dicoretion' style={{ cursor: 'pointer', width: "16%" }}>
            <img width='100%' src={logo}></img>
          </Link>
        </Box>

        <Link to='/ordering' style={{ textDecoration: 'none', marginTop: 20 }}>
          <Badge badgeContent={listOrder.length} color='success'
            sx={{
              '& .MuiBadge-badge': {
                top: '0.2rem !important',
                right: '1rem !important',
              },
              paddingRight: '1rem',
              display: { xs: 'flex', md: 'flex', lg: 'none' },
              '& .MuiBadge-colorSuccess': {
                backgroundColor: '#79BD68 !important'
              }
            }}>
            <img height={40} src={basket}></img>
          </Badge>
        </Link>
      </Stack>

      <Stack direction='column'
        width={{ xs: '100%', sm: '75%', md: '65%', lg: '50%' }}
        justifyContent='space-between'
        alignContent='flex-start'
        paddingTop={3}
      //spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          width="100%"
          //paddingBottom={{ xs: 2, md: 2, lg: 0 }}
          paddingLeft={{ xs: 1, md: 2, lg: 0 }}
          paddingRight={{ xs: 1, md: 2, lg: 0 }}
        >
          <OutlinedInput sx={{
            width: '100%',
            borderEndEndRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: '#D9D9D9',
            borderBottomColor: 'white !important',
            backgroundColor: "#eee",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none"
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
              }
            },
            "&input::placeholder": {
              fontSize: "30px"
            }
          }}
            value={strText}
            variant="outlined"
            placeholder='Поиск по каталогу (артикул ,vin, наименование)'
            size="small"
            onChange={handleChange}
            inputProps={{
              onKeyDown: enterLink,
            }}

          />
          <Link to={`/products-list/${strText}`} className='none-dicoretion'
          >
            <Button sx={{
              height: { xs: 37, sm: 37, md: 40 },
              '&:hover': {
                backgroundColor: '#56854a !important'
              }
            }} height='32px' className='search-but'>Найти</Button></Link>
        </Stack>

        <FormControlLabel
          control={
            <Checkbox
              color="success"
              sx={{
                marginLeft: { xs: 1, sm: 1, md: 0 },
                color: 'white',
              }}
              onChange={e => dispatch(eventChecked(e.target.checked))}
            />}
          label="Искать по артиклу"
          labelPlacement="end"
          checked={isChecked}
          sx={{
            fontWeight: 700,
            fontSize: 20,
            lineHeight: 24,
            color: 'white',
          }}
        />
      </Stack>




      <Stack
        display={{ xs: 'none', md: 'none', lg: 'flex' }}
        direction="column"
        justifyContent="start"
        alignItems="start"
        spacing={2}>
        <a href="tel:89994633000" className='none-dicoretion'>
          <Stack

            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={1}>

            <Link to='https://wa.me/79994633000' className="none-dicoretion" target="_blank">
              <img src={logowhats}></img>
            </Link>
            <TextNavbar >8(999)463-30-00</TextNavbar>

          </Stack>
        </a>
        <Link to='/ordering' style={{ textDecoration: 'none' }}>
          <Stack direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}>
            <Badge badgeContent={listOrder.length} color='success'
              sx={{
                '& .MuiBadge-colorSuccess': {
                  backgroundColor: '#79BD68 !important',

                  marginRight: '2px',
                  marginTop: '2px'
                }
              }}>
              <img src={basket}></img>
            </Badge>
            <TextNavbar
              display={{ xs: 'none', md: 'none', lg: 'flex' }}
              sx={{ fontSize: 18 }}>КОРЗИНА ПОКУПОК</TextNavbar>
          </Stack>
        </Link>
      </Stack>
    </Stack>
  );
}
