import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GetPage } from '../../../api/axiosApi';

export const fetchGetPage = createAsyncThunk(
  'product/fetchGetPage',
  async (obj) => {
    const response = await GetPage(obj);    
    return response;
  }
)

export const generalPageSlice = createSlice({
  name: 'basket',
  initialState: {
    data:{}
  },
  reducers: {    
    
  },
  extraReducers: (builder) => {
    builder      
      .addCase(fetchGetPage.fulfilled, (state, action) => {  
         state.data=action.payload;
      })     
  }
})



export const {  } = generalPageSlice.actions

export default generalPageSlice.reducer
