import { Fragment, useEffect, useState, useRef } from "react";
import React from "react";
import {
    Box, Stack, Typography, Table,
    TextField, TableBody, Input,
    Checkbox, FormControlLabel, Alert, CircularProgress, TableContainer, TableHead, TableCell, TableRow, FormControl, Select, MenuItem, Button
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import './Ordering.css';
import { useSelector, useDispatch } from 'react-redux';
import { changePrice, changePriceLoadPage, removeProduct } from "./slices/basket/slice";
import { TypographyPrice } from '../style/Styled.js'
import {
    fetchEventAddOrder,
    changeName, changePhonenumber, changeAddresstransfer, changeisTransfer,
    changeBodyClient
} from "./slices/basket/slice";
import { Link } from "react-router-dom";



export const Ordering = () => {    

    const dispatch = useDispatch();

    const [count, setCount] = useState(0);

    const { listOrder, generalPrice,
        name, phonenumber, addresstransfer, isTransfer, isAddOrder, resultAddOrder,
        bodyClient } = useSelector(state => state.basket);

    function handlerev(e) {
        e.preventDefault();
    }

    useEffect(() => {
        setCount(listOrder.length);
    }, [listOrder])

    useEffect(() => {
        setCount(listOrder.length);
        dispatch(changePriceLoadPage())
    }, [])

    const [error, setError] = useState(false);
    const [textError, setTextError] = useState("");

    const createOrder = () => {
        if (phonenumber !== "" || null || undefined) {
            setError(false);
            setTextError("")
            window.ym(95266313,'reachGoal','place-order');
            dispatch(fetchEventAddOrder())
        }
    }

    const handlerInputPhone=(e)=>{
        const val = e.target.value;
        if (val.match(/[^0-9]/)) {
          return e.preventDefault();
        }
        dispatch(changePhonenumber(val))        
    }

    useEffect(() => {
        if (phonenumber === "" || null || undefined) {
            setError(true);
            setTextError("Необходимо указать номер телефона")
        }else{
            setError(false);
            setTextError("");
        }
    }, [phonenumber])

    const menuItems = (row) => {
        let items = [];
        for (let i = 1; i <= row.obj.count; i++) {
            items.push(<MenuItem key={row.obj.number + '_ordering_' + i} value={i}>{i}</MenuItem>)
        }

        return (<Select
            key={row.number + '_select'}
            sx={{
                backgroundColor: '#B9C8B5',
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#B9C8B5',
                    borderRadius: '4px',
                }
            }}
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={row.count}
            onChange={(e) => {
                dispatch(changePrice({ row: row, value: e.target.value }))
            }}
        >
            {items}
        </Select>)
    }


    return (
        <Fragment>
            {
                count !== 0 ?
                    <Box marginBottom={3}>
                        <Box sx={{ width: '100%', textAlign: 'center' }}>
                            <Typography className="p-2" sx={{
                                textTransform: 'uppercase', color: '#79BD68',
                                fontSize: 24, fontWeight: 900
                            }}>Корзина</Typography>
                        </Box>
                        <Stack
                            direction="column"
                            spacing={1}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={150} align="left">Артикул</TableCell>
                                            <TableCell align="left">Информация</TableCell>
                                            <TableCell width={100} align="center">Кол-во</TableCell>
                                            <TableCell width={30} align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            listOrder.map((row, index) => {
                                                return (
                                                    <TableRow selected={false} hover={false} sx={{ cursor: 'pointer' }} role="checkbox" tabIndex={-1} key={row.obj.number + '_row'}>
                                                        <TableCell align="left" >{row.obj.number}</TableCell>
                                                        <TableCell align="left" >
                                                            <Stack
                                                                direction="column"
                                                                justifyContent="flex-start"
                                                                alignItems="flex-start"
                                                            >
                                                                <Box>{row.obj.nameFull}</Box>
                                                                <Box sx={{ fontSize: 20, fontWeight: 'normal' }}>{row.obj.prices} руб/шт</Box>
                                                            </Stack>

                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <FormControl
                                                                sx={{ maxWidth: 100 }}
                                                                size="small"
                                                            >
                                                                {menuItems(row)}
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell onClick={e => dispatch(removeProduct({ id: row.obj.id, price: row.priceGenerel }))} align="center">
                                                            <DeleteForeverIcon
                                                                sx={{
                                                                    '&:hover': { color: 'red' }
                                                                }}></DeleteForeverIcon>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box sx={{
                                minWidth: '100%', textAlign: 'end',
                                textAlign: { xs: 'center', md: 'end' }
                            }} className='p-3'>
                                <TypographyPrice
                                    sx={{ fontSize: 24, fontWeight: 900, textTransform: 'uppercase' }}>Итоговая сумма заказа: {generalPrice.toFixed(2)} руб.
                                </TypographyPrice>
                            </Box>
                        </Stack>

                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            justifyContent="center"
                            alignItems={{ xs: 'center', md: 'start' }}
                            spacing={2}
                            width='100%'
                            padding={{ xs: 1, md: 0 }}
                        >
                            <TextField
                                key='fieldName'
                                value={name}
                                onChange={e => dispatch(changeName(e.target.value))}
                                sx={{ backgroundColor: '#B9C8B5', borderRadius: '4px', width: '20rem' }}
                                placeholder="ИМЯ, ФАМИЛИЯ" hiddenLabel={true} variant="outlined" />

                            <TextField
                                // height='100%'
                                inputProps={{ inputMode: 'numeric' }}
                                error={error}
                                helperText={textError}
                                key='fieldPhone'
                                value={phonenumber}
                                onChange={e => handlerInputPhone(e)}
                                sx={{ backgroundColor: '#B9C8B5', borderRadius: '4px', width: '20rem' }}
                                placeholder="НОМЕР ТЕЛЕФОНА" hiddenLabel={true} variant="outlined"
                            // inputComponent={TextMaskCustom}
                            />


                        </Stack>
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            justifyContent="center"
                            alignItems={{ xs: 'center', md: 'start' }}
                            spacing={2}
                            marginTop={2}
                            padding={{ xs: 1, md: 0 }}
                        >
                            <TextField
                                key='fieldBody'
                                value={bodyClient}
                                onChange={e => dispatch(changeBodyClient(e.target.value))}
                                sx={{ backgroundColor: '#B9C8B5', borderRadius: '4px', width: '20rem' }}
                                placeholder="Номер кузова" hiddenLabel={true} variant="outlined" />
                            <TextField
                                key='fieldAddress'
                                value={addresstransfer}
                                onChange={e => dispatch(changeAddresstransfer(e.target.value))}
                                sx={{ backgroundColor: '#B9C8B5', borderRadius: '4px', width: '20rem' }}
                                placeholder="АДРЕС ДОСТАВКИ" hiddenLabel={true} variant="outlined" />
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="start"
                            marginTop={2}
                            padding={{ xs: 1, md: 0 }}>
                            <FormControlLabel
                                control={<Checkbox
                                    color="success" value={isTransfer}
                                    onChange={e => dispatch(changeisTransfer(e.target.checked))} />}
                                label="САМОВЫВОЗ, ДОСТАВКА НЕ НУЖНА"
                                labelPlacement="end"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: 20,
                                    lineHeight: 24,
                                    color: '#997C7C',
                                }}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="start"
                            marginTop={2}
                            padding={{ xs: 1, md: 0 }}
                            height='47px'>

                            <Box sx={{ m: 1, position: 'relative' }}>

                                <Button
                                    onClick={createOrder}
                                    className='search-but filter'
                                    disabled={isAddOrder}
                                    type="submit"
                                    sx={{
                                        width: { xs: '100% !important', md: '300px !important' },
                                        '&:hover': {
                                            backgroundColor: '#56854a !important'
                                        },
                                        "&.Mui-disabled": {
                                            background: "#8a8a8a!important",
                                        }
                                    }}>Оформить заказ</Button>
                                {
                                    isAddOrder && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: 'green',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }} />
                                    )}
                            </Box>
                        </Stack>
                    </Box> :
                    <Stack
                        sx={{ width: '100%' }}
                        justifyContent='center'
                        alignItems='center'
                    >
                        {
                            resultAddOrder.code === 200 && (
                                <Link style={{ width: '100%', textDecoration: 'none' }} to="/">
                                    <Alert sx={{ width: '100%' }}>
                                        Заказ отправлен. Перейти на главную страницу?
                                    </Alert>
                                </Link>
                            )
                        }

                        <Link style={{ width: '100%', textDecoration: 'none' }} to="/products-list">
                            <Alert severity="warning">
                                Необходимо добавить товар в корзину...
                            </Alert>
                        </Link>

                    </Stack>

            }


        </Fragment>
    )//isAddOrder
}