import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import {Products} from './pages/ProductInfo';
import { Ordering } from "./pages/Ordering";
import { ProductInfo } from "./pages/Information";
import { Address } from "./pages/Address";
import MainLayout from "./layout/MainLayout";
import { OtherLayout } from "./layout/OtherLayout";
import AboutCompany from "./pages/OfCompany";

const AppRoutes = [
  {
    layout: MainLayout,
    index: true,
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
    path:'/product-info/:number',
    element: <ProductInfo />
  },
  {
    path:'/products-list/:search?',
    element: <Products />
  },
  {
    layout: OtherLayout,
    path:'/products-list',
    element: <Products />
  },
  {
    path:'/ordering',
    element:<Ordering />
  },
  {
    path:'/address',
    element:<Address />
  },
  {
    path:'/about',
    element:<AboutCompany />
  }

];

export default AppRoutes;
