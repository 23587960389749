import React, { Fragment, useEffect, useRef, useState } from "react";
import EmptyData from "../components/product-info/EmptyData";
import { useParams } from "react-router-dom";
import {
    LinearProgress, Stack,
    Table, TableBody, TableContainer, TablePagination, TableCell, TableRow,
    Avatar, Typography, Box, Button, IconButton
} from '@mui/material';
import './ProductInfo.css'
import { useSelector, useDispatch } from 'react-redux'
import { fetchGetFilter } from './slices/productSlice'
import { addProduct, addProductFromList } from './slices/basket/slice';
import { TitleLable, DiscriptionLable, CellList } from '../style/Styled.js'
import ruble from '../img/ruble.svg';
import { Link } from "react-router-dom";
import imageList from '../img/no_foto.png'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import PictureView from "../components/PictureView";

export const Products = () => {
    const {
        dataSearch,
        isLoadData,
        isChecked
    } = useSelector(state => state.product);

    let { search } = useParams();
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [isDataEmpty, setDataEmpty] = useState(false)

    const [open, setOpen] = useState(false);
    const [picture, setPicture] = useState('');
    const [number, setNumber]=useState("");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (dataSearch.obj != null || undefined)
            dataSearch.obj.length == 0 ? setDataEmpty(true) : setDataEmpty(false)
    }, [dataSearch])

    useEffect(() => {
        dispatch(fetchGetFilter({ "stringsearch": search, "searchOfArticle": isChecked, "pagesize": 50, "pagenumber": page + 1 }));
    }, [page])

    useEffect(() => {
        setPage(0);
        dispatch(fetchGetFilter({ "stringsearch": search, "searchOfArticle": isChecked, "pagesize": 50, "pagenumber": page + 1 }));
    }, [search])


    const addProductAndCheck = (item) => {
        window.ym(95266313,'reachGoal','add-to-cart');
        dispatch(addProductFromList(item));
    }


    const price = (value) => {
        let res = String(value).split(".");
        return res;
    }

    const handleOpen = (url, number) => {
        setOpen(true);
        setPicture(url ?? "")
        setNumber(number);
    }

    const handleClose = () => {
        setOpen(false);
    }


    const Component = () => {
        return <>
            {
                isLoadData == false ?
                    <Box >
                        <LinearProgress color="success" />
                    </Box>
                    :
                    <Box display='flex' flexDirection='column' >
                        {
                            isDataEmpty ?
                                <EmptyData />
                                :
                                <>
                                    <TableContainer margin='auto' >
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableBody>
                                                {dataSearch.obj
                                                    .map((row, index) => {
                                                        return (
                                                            <TableRow sx={{ cursor: 'pointer' }} hover role="checkbox" tabIndex={-1} key={row.number}>
                                                                <TableCell align="start" sx={{ width: 150 }}>
                                                                    <Stack direction="row"
                                                                        justifyContent="center"
                                                                        alignItems="center">
                                                                        {row.photo === '' || row.photo === null ?
                                                                            <Avatar
                                                                                onClick={_ => handleOpen(imageList, row.number)}
                                                                                src={imageList}
                                                                                sx={{ minHeight: 80, minWidth: 80 }}
                                                                            >

                                                                            </Avatar>
                                                                            :
                                                                            <img src={row.photo} 
                                                                                onClick={_ => handleOpen(row.photo,row.number)}
                                                                                className="img-thumbnail rounded"
                                                                                style={{ minWidth: 150, height: 150 }}></img>
                                                                        }
                                                                    </Stack>

                                                                </TableCell>
                                                                <CellList>
                                                                    <Link to={`/product-info/${row.number}`} className="none-dicoretion color-list">
                                                                        <Stack direction="column"
                                                                            justifyContent="flex-start"
                                                                            alignItems="flex-start">

                                                                            <TitleLable>{row.nameFull}</TitleLable>

                                                                            <DiscriptionLable>{row.number}</DiscriptionLable>
                                                                            <DiscriptionLable>{
                                                                                row.model != null || undefined ? row.model.replace(/[^a-zA-Z0-9, ]/g, '') : ''
                                                                            }</DiscriptionLable>
                                                                            <Stack direction="row"
                                                                                justifyContent="flex-start"
                                                                                alignItems="center"
                                                                                spacing={1}>
                                                                                <Stack direction="row"
                                                                                    justifyContent="flex-start"
                                                                                    alignItems="flex-start">
                                                                                    <Typography sx={{ fontWeight: '500', fontSize: 30 }} color='black'>{price(row.prices)[0]}</Typography>
                                                                                    <Typography sx={{ fontWeight: '500', paddingTop: '5px !important', marginLeft: '2px !important', fontSize: 20 }} color='black'>{String(price(row.prices)[1]).length === 1 ? price(row.prices)[1] + '0' : price(row.prices)[1]}</Typography>
                                                                                </Stack>
                                                                                <img height={15} sx={{ maxWidth: 15, fontWeight: 400 }} src={ruble} />
                                                                                <Typography sx={{ marginLeft: '0 !important', fontWeight: '100' }} color='black'>/шт</Typography>
                                                                            </Stack>

                                                                        </Stack>
                                                                    </Link>
                                                                </CellList>
                                                                <TableCell>
                                                                    <Box sx={{ borderRadius: 1, borderColor: 'green', border: 1, padding: 1 }}>
                                                                        <Typography sx={{ marginLeft: '0 !important', fontWeight: '100' }} color='black'>Бренд: {row.brand}</Typography>
                                                                        <Typography sx={{ marginLeft: '0 !important', fontWeight: '100' }} color='black'>В наличии: {row.count}</Typography>
                                                                    </Box>

                                                                </TableCell>
                                                                <TableCell>
                                                                    <IconButton sx={{ color: 'green' }} size="large" onClick={() => { addProductAndCheck(row) }}>
                                                                        <ShoppingBasketIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        sx={{
                                            "& .MuiTablePagination-selectLabel": {
                                                display: 'none !important'
                                            },
                                            "& .MuiInputBase-input": {
                                                display: 'none !important'
                                            },
                                            "& .MuiTablePagination-selectIcon": {
                                                display: 'none !important'
                                            },
                                            "& .MuiTablePagination-displayedRows": {
                                                margin: 0
                                            }
                                        }}
                                        component="div"
                                        count={dataSearch.countTable}
                                        rowsPerPage={50}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                </>
                        }

                    </Box>

            }


        </>

    }


    return (
        <>
            <Component />
            <PictureView handleClose={handleClose} url={picture} open={open} number={number}/>
        </>

    )
}