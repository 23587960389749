import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import readXlsxFile from 'read-excel-file';


export class Counter extends Component {
  static displayName = Counter.name;

  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
    this.incrementCounter = this.incrementCounter.bind(this);
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  handlerChnage(e){
    readXlsxFile(e.target.files[0]).then((rows) => {
      let t = rows;
      // `rows` is an array of rows
      // each row being an array of cells.
    })
  }


  render() {
    return (
      <div>
        <h1>Counter</h1>
        <h1>TEST</h1>
        <p>This is a simple example of a React component.</p>

        <p aria-live="polite">Current count: <strong>{this.state.currentCount}</strong></p>
       
        <button className="btn btn-primary" onClick={this.incrementCounter}>Increment</button>
        <button className="btn btn-primary" onClick={this.incrementCounter}>Test but TEST2</button>
        <TextField
          helperText="Please enter your name"
          id="demo-helper-text-misaligned"
          label="Name"
          type='file'
          onChange={e=>this.handlerChnage(e)}
        />
      </div>
    );
  }
}
