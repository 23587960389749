import { connect } from "react-redux"
import React, { Fragment, useRef } from "react";
import { Box, styled, Stack, TextField, FormLabel, FormControl } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';


function Filter({children,defaultValue, action,onChangeEvent,...props}) {

    

    return (
            <Stack direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{ width: '100%' }}
                padding='10px'>
                <FormControl sx={{ width: '70%' }} >
                    <FormLabel className="input-label" sx={{
                        backgroundColor: '#B9C8B5',
                        textTransform: 'uppercase',
                        padding: '10px',
                        borderTopRightRadius: 5,
                        borderTopLeftRadius: 5
                    }}>{props.filterName}</FormLabel>
                    {children}
                   <TextField  
                        name={props.name}
                        defaultValue={defaultValue!==""?defaultValue:""}
                        focused
                        autoFocus={true}
                        placeholder={props.placeholder}
                        sx={{                            
                            
                            backgroundColor: '#CBD6C8',
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                            "& input": {
                                height: 'auto',
                                padding: '10px'
                            },
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    border: 'none'
                                },
                                "& fieldset": { display: 'none' },
                            },
                        }}
                    />
                </FormControl>

            </Stack>
    )
}

export default Filter;