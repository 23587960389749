import {Typography, styled,Stack,TableCell} from "@mui/material";

export const CellList=styled(TableCell)({
    fontWeight: 'bold', 
    lineHeight:24
})

export const TitleLable=styled(Typography)({
     fontSize:18
});

export const DiscriptionLable=styled(Typography)({
    fontWeight: 'regular',
    fontSize: 12,
    color:'rgb(130, 139, 139) !important'    
});

export const TypographyPrice = styled(Typography)({
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 20,
    color: '#FF5C00',
    textTransform: 'uppercase'
});    

export const TypographyHeaderPage=styled(Typography)({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 20,
    textTransform: 'uppercase'
});