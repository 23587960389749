import { Box } from "@mui/material";

export const OtherLayout=({ children })=>{
    return(
        <Box>
            <>2</>
            {children}
        </Box>
 
    )
}

//export default MainLayout;