import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavMenu } from './NavMenu';
import { Footer } from '../components/main/footer';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector, useDispatch } from 'react-redux';
import { Filters } from './menu/filters';
import { changeDefaultValue } from '../pages/slices/productSlice';
import { Stack, Box, Drawer } from '@mui/material';
import './Layout.css'
import { changeActiveMenu } from '../pages/slices/layout/state';
import logo from '../img/logo-jap-white 1.svg';
import { IndexBody } from './main';

export const Layout = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isHome, setIsHome] = useState(true);
  const [isInfoPage, setIsInfoPage] = useState(false);
  const { activeMenu } = useSelector(state => state.layout);
  const handlerFilter = (e) => {
    e.preventDefault();
    dispatch(changeDefaultValue(e.target));
  }

  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(changeActiveMenu());
  };

  useEffect(() => {
    if (location.pathname === "/")
      setIsHome(true);
    else
      setIsHome(false);
  }, [location])

  useEffect(() => {
    if (location.pathname === "/address")
      setIsInfoPage(true);
    else
      setIsInfoPage(false);
  }, [location])


  const page = (value) => {

  }


  return (
    <>
      <Box
        className='container-stack'
      >
        <NavMenu />
        <Box className='content' flex={1} sx={{ width: '100%' }}>
          {props.children}
        </Box>
        <Footer />
      </Box>

      <Drawer
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#383838',
            width: '80%',
            justifyContent: 'start',
            alignItems: 'center'
          }
        }}

        anchor='left'
        open={activeMenu}
        onClose={e => toggleDrawer(e)}
      >
        <img style={{ width: '80%' }} src={logo}></img>
        <form method="post" style={{ width: '100%' }} onSubmit={(e) => handlerFilter(e)}>
          <Filters />
        </form>
      </Drawer>
    </>

  );
}


