import { Stack, Card, CardActionArea, CardContent, Typography, Box } from "@mui/material";
import React, { Fragment } from "react";
import company from '../../img/company 1.png';
import services from '../../img/geolocation 1.svg';
import catalog from '../../img/catalog 1.png'
import { Link, Route, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { changeCategory, fetchGetFilter } from "../../pages/slices/productSlice";


export const IndexBody = () => {
    const dispatch = useDispatch();
    const catalog_list = useSelector(state => state.product.catalogList)

    const arrayMenu = [
        { url: '/products-list', type: '', image: "https://www.nadodetali.ru/images/catalog 1.png", title: 'Общий каталог', h: 190, w: 254 },
        { url: '/address', image: services, title: 'Наши адреса', h: 190, w: 254 },
        // { url: '/about', image: company, title: ' О компании', h: 190, w: 254 },
    ];

    const icons = arrayMenu.map((icon, index) => (

        <Card key={index} sx={{ minWidth: 200, width:320, height: 261 }} >
            <NavLink to={icon.url} className="none-dicoretion" /*onClick={()=>handlerEvent(icon.action,icon.url)}*/>
                <CardActionArea sx={{ height: '100%' }} >
                    <CardContent sx={{ height: '100%', padding: 0 }}>
                        <Stack className="search-but" color="success" variant="contained"
                            sx={{
                                textTransform: 'uppercase',
                                backgroundColor: '#79BD68',
                                borderEndEndRadius: 0,
                                borderEndStartRadius: 0,
                                height: '41px',
                                justifyContent: 'center',
                                display: 'flex'
                            }}>
                            <Typography sx={{ fontSize: 16, textAlign: 'center' }}>{icon.title} </Typography>
                        </Stack>

                        <Stack direction="row" height='70%'
                            justifyContent="center"
                            alignItems="center"
                            spacing={0}>
                            <img height='100%' sx={{ maxWidth: 100 }} src={icon.image} />
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </NavLink>
        </Card>

    ));

    return (
        <Box sx={{ width: '100%', height: { sx: '100%', lg: 'calc(100% - 171px)' } }} className="d-flex justify-content-center"
            paddingBottom={4}>
            <Stack
                direction='column'
                justifyContent="center"
                width='100%'                
            >
                <Stack direction={{ xs: 'column', sm:'row', md:'row' , lg: 'row' }}
                    justifyContent="center"
                    alignItems="center"                    
                    spacing={10}
                    marginTop={5}
                    marginBottom={5}
                    //flexWrap="wrap"
                >
                    {icons}
                </Stack>        
                <Box sx={{width:'100%'}} paddingLeft= {{xs:0.2,sm:5,md:10}} paddingRight={{xs:0.2,sm:5,md:10}}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <iframe style={{ width: "100%", height: "100%", padding: 0, margin: 0 }}
                            id="myReviews__block-widget">
                        </iframe>
                    </div>
                </Box>     
            </Stack>            
        </Box>
    )
}